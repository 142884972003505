import CSelectSearch from '@/components/CSelectSearch'

export default {
  components: { CSelectSearch },
  mounted() {
    this.mountedComponent = true
    this.loadProjects = true
    this.loadCompanies = true

    this.$http.projects
      .fetchProjectsList()
      .then((res) => {
        this.projectListMixin = res.data.data
          .sort((a, b) => a.id - b.id)
          .reverse()
        this.loadProjects = false
      })
      .catch(() => {
        this.loadProjects = false
      })

    this.$http.companies
      .fetchCompaniesList({
        params: {
          page: 1,
          per_page: 99999999,
          search: '',
          sort_field: '',
          sort_direction: '',
        },
      })
      .then((res) => {
        this.companyListMixin = res.data.data
        this.loadCompanies = false
      })
      .catch(() => {
        this.loadCompanies = false
      })
  },
  data() {
    return {
      mountedComponent: false,
      selectProject: this.$route.params.id,
      selectCompany: this.$route.params.id,
      projectListMixin: [],
      companyListMixin: [],
      loadProjects: false,
      loadCompanies: false,
    }
  },
  watch: {
    selectProject() {
      this.$router.push(
        this.routerAfterChange || {
          name: this.getRouteName(),
          params: { id: this.selectProject },
        },
      )
    },
    selectCompany() {
      this.$router.push(
        this.routerAfterChange || {
          name: this.$route.name,
          params: { id: this.selectCompany },
        },
      )
    },
  },
  computed: {
    lastProjectId() {
      const ids = this.projectListMixin.map((object) => object.id)
      return Math.max(...ids)
    },
    routerAfterChange() {
      return null
    },
    loadingBreadcrumbs() {
      return this.loadProjects && this.loadCompanies
    },
    id() {
      return this.$route.params.id
    },
    projectOption() {
      return (
        this.projectListMixin
          .map((i) => {
            return {
              to: { name: this.$route.name, params: { id: i.id } },
              value: `${i.id}`,
              label: i.name,
              tasks: i.tasks_count,
            }
          })
          // .sort(i => i.value !== `${this.$route.params.id}` ? 1 : -1)
          .sort((a, b) => b.tasks - a.tasks)
      )
    },
    companyOption() {
      return this.companyListMixin.map((i) => {
        return {
          to: { name: this.$route.name, params: { id: i.id } },
          value: `${i.id}`,
          label: i.name,
        }
      })
      // .sort(i => i.value !== `${this.$route.params.id}` ? 1 : -1)
    },
  },
  methods: {
    getCurrentIdValue(type) {
      const el = this[type].find((i) => i.value === this.$route.params.id)
      return el ? el.label : ''
    },
    sortByTaskCount(data) {
      return data.sort((a, b) => b - a)
    },
    getRouteName() {
      let name = this.$route.name
      if (this.$route.meta.parent && this.$route.meta.parent === 'projectTask') {
        name = 'ProjectDashboard'
      }

      return name
    },
  },
}
