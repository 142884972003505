<template>
  <div>
    <discussion-reply-item
      v-for="reply in replies"
      :key="reply.id"
      style="margin-bottom: 2rem"
      :reply="reply"
      :reply-form-open="showReplyForm"
      @edit="
        () => {
          showReplyForm = false
          newReplyText = ''
        }
      "
    />
    <div v-if="showReplyForm" style="padding-top: 2rem">
      <c-form-textarea
        v-model="newReplyText"
        :invalid="!!validationErrors.newReplyText"
        @click.stop
        @keyup.ctrl.enter="handleSubmit"
        @blur="validateField"
      />
      <CFormText v-if="validationErrors.newReplyText" style="color: red">
        {{ validationErrors.newReplyText }}
      </CFormText>
      <div class="buttons d-flex justify-content-end mt-3">
        <button
          class="btn btn-outline-secondary mr-2 btn-sm"
          @click.stop.prevent="handleCancel"
        >
          Cancel
        </button>
        <button
          :disabled="loading || !!validationErrors.newReplyText"
          class="btn btn-primary mr-2 btn-sm"
          @click.stop.prevent="handleSubmit"
        >
          Submit
        </button>
      </div>
    </div>
  </div>

  <div>
    <button
      v-if="!showReplyForm && canReply && canEdit"
      class="btn btn-primary btn-sm"
      @click.stop.prevent="showForm"
    >
      Reply
    </button>
  </div>
</template>

<script setup>
import { defineProps, onMounted, computed, ref, defineEmits, watch } from 'vue'
import ApiService from '@/services/ApiService'
import DiscussionReplyItem from '@/views/Projects/Project/Tasks/Task/View/ReviewApprove/partials/DiscussionReplyItem.vue'
import { useStore } from 'vuex'
import validatorRules from '@/utils/validator'
import rules from '@/utils/validator/rules'

const validationErrors = ref({})
const showReplyForm = ref(false)
const replies = ref([])
const newReplyText = ref('')
const store = useStore()
const validator = validatorRules({
  newReplyText: [rules.required, rules.strMax(10000)],
})
const props = defineProps({
  contractApprove: {
    type: Boolean,
    default: () => ({}),
  },
  discussion: {
    type: Object,
    default: () => ({}),
  },
  discussionEditMode: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['form-showed'])

const canReply = computed(() => props.contractApprove)

const canEdit = computed(() => {
  return store.state.tasks.permissions.reviewAndApproveEdit
})

const loading = ref(false)

const handleSubmit = () => {
  validateField()
  if (!validationErrors.value.newReplyText) {
    loading.value = true
    ApiService.api.discussions
      .storeReply({
        text: newReplyText.value,
        discussion_id: props.discussion.discussionId,
      })
      .then((res) => {
        if (res.status === 201) {
          store.commit('discussions/UPDATE_DISCUSSION', {
            replies: replies.value.push(res.data.data),
          })
          handleCancel()
        }
      })
      .finally(() => (loading.value = false))
  }
}

const showForm = () => {
  showReplyForm.value = true
  emit('form-showed')
}

watch(
  () => props.discussionEditMode,
  (to) => {
    if (to) {
      showReplyForm.value = false
      newReplyText.value = ''
    }
  },
)

const handleCancel = () => {
  newReplyText.value = ''
  showReplyForm.value = false
}

const validateField = () => {
  validationErrors.value['newReplyText'] = validator.validate(
    'newReplyText',
    newReplyText.value,
  )
}

onMounted(() => {
  replies.value = props.discussion.replies ?? []
})
</script>

<style scoped></style>
