<template>
  <CModal
    :visible="showModal"
    backdrop="static"
    class="discussion-create-modal"
    @close="closeModal"
  >
    <CModalBody>
      <div
        class="section-causer align-items-center justify-content-start d-flex"
      >
        <div class="avatar-wrapper">
          <Avatar
            class="avatar"
            :size="70"
            :src="authUser.photo"
            :display-name="[authUser.first_name, authUser.last_name]"
          />
          <!--          <img :src="authUser.photo" class="avatar" />-->
          <img src="@/assets/images/chat.png" class="comment-icon" />
        </div>
        <div class="bio d-flex flex-column">
          <span class="ml-2">{{
            authUser.first_name + ' ' + authUser.last_name
          }}</span>
          <!--          <small class="text-secondary ml-2">Subcontractor</small>-->
        </div>
      </div>

      <button
        class="review-approve__toggler"
        :class="{ activeBtn: showEdit }"
        @click="toggleBetweenState"
      >
        Edit
      </button>

      <button
        class="review-approve__toggler"
        :class="{ activeBtn: !showEdit }"
        @click="toggleBetweenState"
      >
        Delete
      </button>
      <div v-show="showEdit">
        <div class="section-discussion my-4">
          <template v-if="isSuggestion">
            <div class="replaced-text pb-3">
              <small class="text-secondary">From:</small>
              <div>{{ replacedText }}</div>
            </div>
          </template>
          <div class="d-flex align-items-center">
            <span class="clickable" @click="handleToggleSuggestion">
              <CIcon name="cilPencil" class="mr-2" />
              {{ $t('Suggested edit') }}
              <sup class="text-error">*</sup>
            </span>
            <section-visibility-toggle
              class="ml-2"
              :is-visible="isSuggestion"
              @update="(value) => (isSuggestion = value)"
            />
          </div>

          <CFormTextarea
            v-show="isSuggestion"
            v-model="suggestionText"
            v-autosize
            required
            class="w-100 discussion-modal__textarea"
            :class="{ 'discussion-modal__input-error': inputErrorSuggest }"
            @blur="showError"
          />
          <CFormText
            v-if="inputErrorSuggest && isSuggestion"
            style="color: red; position: absolute"
            class="text-error"
            >{{ errorTextSuggest }}
          </CFormText>
        </div>
        <div class="section-comment mb-3">
          <div class="d-flex align-items-center">
            <span class="clickable" @click="handleToggleComment">
              <CIcon name="cilCommentSquare" class="mr-2" />
              Please explain why you are requesting an edit
              <sup class="text-error">*</sup>
            </span>
            <section-visibility-toggle
              class="ml-2"
              :is-visible="isComment"
              @update="(value) => (isComment = value)"
            />
          </div>

          <CFormTextarea
            v-show="isComment"
            v-model="commentText"
            v-autosize
            class="w-100 discussion-modal__textarea"
            :class="{ 'discussion-modal__input-error': inputErrorComment }"
            @keyup.enter.ctrl.exact="handleConfirm"
            @blur="showErrorComment"
          />
          <CFormText
            v-if="inputErrorComment && isComment"
            style="color: red; position: absolute"
            class="text-error"
            >{{ errorTextComment }}
          </CFormText>
        </div>
      </div>
      <div v-show="!showEdit">
        <div class="section-discussion my-4">
          <template v-if="isSuggestion">
            <div class="replaced-text pb-3">
              <small class="text-secondary">From:</small>
              <div>{{ replacedText }}</div>
            </div>
          </template>
          <div class="d-flex align-items-center">
            <span class="clickable" @click="handleToggleSuggestion">
              <CIcon name="cilPencil" class="mr-2" />
              {{ $t('Suggested edit') }}
              <sup>&nbsp;&nbsp;</sup>
            </span>
            <section-visibility-toggle
              class="ml-2"
              :is-visible="isSuggestion"
              @update="(value) => (isSuggestion = value)"
            />
          </div>

          <CFormTextarea
            v-show="isSuggestion"
            v-model="suggestionText"
            v-autosize
            disabled
            class="w-100 discussion-modal__textarea"
          />
        </div>
        <div class="section-comment mb-3">
          <div class="d-flex align-items-center">
            <span class="clickable" @click="handleToggleComment">
              <CIcon name="cilCommentSquare" class="mr-2" />
              Please explain why you are requesting an edit
              <sup class="text-error">*</sup>
            </span>
            <section-visibility-toggle
              class="ml-2"
              :is-visible="isComment"
              @update="(value) => (isComment = value)"
            />
          </div>

          <CFormTextarea
            v-show="isComment"
            v-model="commentText"
            v-autosize
            class="w-100 discussion-modal__textarea"
            :class="{ 'discussion-modal__input-error': inputErrorComment }"
            @keyup.enter.ctrl.exact="handleConfirm"
            @blur="showErrorComment"
          />
          <CFormText
            v-if="inputErrorComment && isComment"
            style="color: red; position: absolute"
            class="text-error"
            >{{ errorTextComment }}
          </CFormText>
        </div>
      </div>
    </CModalBody>
    <CModalFooter class="flex align-items-center">
      <CButton
        color="primary"
        variant="outline"
        class="modal__btn clear"
        @click="handleCancel"
      >
        {{ $t('Cancel') }}
      </CButton>
      <submit-btn
        class="modal__btn btn-primary"
        color="primary"
        :disabled="!disablePost"
        @click="handleConfirm"
      >
        {{ $t('Post') }}
      </submit-btn>
    </CModalFooter>
  </CModal>
</template>

<script>
import { DiscussionsFactory } from '@/utils/editor/tools/discussionsFactory'
import { editorEvents } from '@/utils/editor/extensions/utils'
import SectionVisibilityToggle from '@/components/Forms/CompanyUsersEditForm/SectionVisibilityToggle'
import Avatar from '@/components/Avatar.vue'

export default {
  name: 'TaskEditDiscussionModal',
  components: { Avatar, SectionVisibilityToggle },
  // inject: ['authUser'],
  props: {
    editor: {
      type: Object,
      default: () => null,
    },
    contractId: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    errorTextSuggest: '',
    errorTextComment: '',
    inputErrorSuggest: false,
    inputErrorComment: false,
    showModal: false,
    commentText: '',
    suggestionText: '',
    replacedJSON: null,
    replacedText: null,
    range: { from: null, to: null },
    discussionFactory: null,
    isSuggestion: true,
    isComment: true,
    showEdit: true,
  }),
  computed: {
    disablePost() {
      return (
        (!this.suggestionText < 1 && this.showEdit && !this.commentText < 1) ||
        (!this.commentText < 1 && !this.showEdit)
      )
    },
  },
  watch: {
    showModal(showModal) {
      if (showModal) {
        this.suggestionText = this.replacedText || null
      }
    },
  },
  methods: {
    showError() {
      if (
        !this.suggestionText.length ||
        (this.suggestionText.length < 1 && this.isSuggestion)
      ) {
        this.inputErrorSuggest = true
        this.errorTextSuggest = 'The field is required'
      } else {
        this.inputErrorSuggest = false
        this.errorTextSuggest = ''
      }
    },
    showErrorComment() {
      if (
        !this.commentText.length ||
        (this.commentText.length < 1 && this.isComment)
      ) {
        this.inputErrorComment = true
        this.errorTextComment = 'The field is required'
      } else {
        this.inputErrorComment = false
        this.errorTextComment = ''
      }
    },
    toggleBetweenState() {
      if (this.showEdit) {
        this.showEdit = !this.showEdit
        this.suggestionText = ''
      } else {
        this.showEdit = !this.showEdit
        this.suggestionText = this.replacedText
      }
    },
    handleToggleSuggestion() {
      this.isSuggestion = !this.isSuggestion
      if (!this.isSuggestion) {
        this.suggestionText = null
      }
    },
    handleToggleComment() {
      this.isComment = !this.isComment
    },
    handleConfirm() {
      this.isSuggestion = true
      this.showErrorComment()
      this.showError()
      if (!this.commentText) return
      let discussion = this.discussionFactory.create({
        isComment: this.isComment,
        isSuggestion: this.isSuggestion,
        suggestionText: this.suggestionText,
        replacedJSON: this.replacedJSON,
        commentText: this.commentText,
        range: this.range,
        isActive: false,
        contractId: this.contractId,
        authorId: parseInt(this.authUser.id),
        authorFullName: this.authUser.fullName,
        authorRole: this.authUser.role.show_name,
      })

      this.$store.commit('discussions/PUSH_DISCUSSION', discussion)

      this.editor
        .chain()
        .emitCustomEvent({
          name: editorEvents.EVENT_DISCUSSION_CREATED,
          discussion,
        })
        .setTextSelection(0)
        .run()

      this.closeModal()
    },
    handleCancel() {
      this.closeModal()
      this.isSuggestion = true
      this.inputErrorSuggest = false
      this.inputErrorComment = false
    },
    closeModal() {
      this.showModal = false

      //reset local range state
      this.resetLocalData()
    },
    resetLocalData() {
      this.range = { from: null, to: null }
      this.commentText = null
      this.suggestionText = null
      this.replacedJSON = null
      this.replacedText = null
      this.isSuggestion = true
      this.showEdit = true
      this.isComment = true
    },
    show({ range, contentJSON, contentText }) {
      this.range = { ...this.range, ...range }
      this.replacedText = contentText
      this.replacedJSON = contentJSON
      this.discussionFactory = new DiscussionsFactory(this.editor)

      this.showModal = true
    },
  },
}
</script>

<style scoped lang="scss">
.discussion-modal {
  &__textarea {
    border: 1px solid #b1b7c1;
    border-radius: 8px;
    min-height: 80px;
    margin-top: 4px;
  }

  &__input-error {
    border: 1px solid red;
  }
}

.section {
  &-causer {
    padding: 10px 0;

    img.avatar {
      height: 70px;
      width: 70px;
      border-radius: 50em;
      border: 1px solid lightgrey;
    }

    img.comment-icon {
      position: absolute;
      margin-left: -20px;
    }
  }
}

.review-approve__toggler {
  border: 2px solid #c1cbd3;
  border-radius: 0 50px 50px 0 !important;
  padding: 2px 25px;
  background-color: transparent;
  position: relative;
  min-width: 105px;

  &:nth-child(2) {
    border: 2px solid #c1cbd3;
    background-color: transparent;
    border-radius: 50px 0 0 50px !important;
    padding: 2px 25px;
    position: relative;
    left: 2px;
  }
}

.activeBtn {
  position: relative;
  z-index: 2;
  border: 2px solid #321fdb !important;
  color: #321fdb !important;
}
</style>
