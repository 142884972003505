<template>
  <c-form-textarea
    v-model="replyText"
    v-autosize
    :invalid="!!validationErrors.replyText"
    @click.stop
    @keyup.ctrl.enter="handleSubmit"
    @blur="validateField"
  />
  <CFormText v-if="validationErrors.replyText" style="color: red">
    {{ validationErrors.replyText }}
  </CFormText>
  <div class="buttons mt-3 d-flex justify-content-between align-items-center">
    <div></div>
    <div>
      <button class="btn btn-outline-secondary mr-2 btn-sm" @click.stop.prevent="handleCancel">Cancel</button>
      <button class="btn btn-primary btn-sm" :disabled="!!validationErrors.replyText" @click.stop.prevent="handleSubmit">Submit</button>
    </div>
  </div>
</template>
<script  setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue'
import ApiService from '@/services/ApiService'
import { useStore } from 'vuex'

import validatorRules from '@/utils/validator'
import rules from '@/utils/validator/rules'

const validator = validatorRules({ replyText: [rules.required, rules.strMax(10000)] })
const store = useStore()
const props = defineProps({
  reply: {
    type: Object,
    default: () => ({}),
  },
})
const emit = defineEmits(['updated', 'cancel'])

/*Editable*/
const replyText = ref('')
const validationErrors = ref({})

const handleSubmit = () => {
  validateField()
  if (!validationErrors.value.replyText) {
    ApiService.api.discussions.updateReply({
      replyId: props.reply.id,
      text: replyText.value
    }).then(res => {
      store.commit('discussions/UPDATE_REPLY', {
        discussionId: props.reply.discussion_id,
        replyId: props.reply.id,
        value: res.data.data,
      })
      emit('updated', {...props.reply, ...{ text: replyText.value }})
    })
  }
}
const handleCancel = () => {
  emit('cancel')
}

const validateField = () => {
  validationErrors.value['replyText'] = validator.validate('replyText', replyText.value)
}

onMounted(() => {
  replyText.value = props.reply?.text ?? ''
})

</script>
