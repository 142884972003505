import { initTaskReviewAndApproveParser} from '@/utils/editor/editorFactory'
import { reactive } from 'vue'

export const discussionStatuses = {
  DISCUSSION_OPEN: 'Open',
  DISCUSSION_APPROVED: 'Approved',
  DISCUSSION_REJECTED: 'Rejected',
}

export class Discussion {
  /**
   * methodTwo description
   * @param  {Object} attrs      discussion data
   * @param  {String} attrs.id decoration uuid
   * @param  {String} attrs.discussionId decoration id
   * @param  {String} attrs.decorationUuid decoration uuid
   * @param  {Object} attrs.range decoration ranges
   * @param  {Number} attrs.range.from decoration range from
   * @param  {Number} attrs.range.to decoration range from
   * @param  {String} attrs.commentText text of discussion comment
   * @param  {String} attrs.suggestionText text of discussion suggestion
   * @param  {String} attrs.replacedHTML HTML content replaced by suggestion text
   * @param  {String} attrs.replacedJSON JSON representation of content replaced by suggestion text
   * @param  {Boolean} attrs.isSuggestion is suggestion flag
   * @param  {Boolean} attrs.isComment is comment flag
   * @param  {Boolean} attrs.isActive does suggestion is activated by click
   * @param  {Number} attrs.contractId contract id discussion related to
   * @param  {String} attrs.createdAt
   * @param  {String|null} attrs.authorAvatar discussion author's avatar url
   * @param  {String} attrs.authorRole discussion's authors' role
   * @param  {String} attrs.authorFullName discussion's authors' full name
   * @param  {String} attrs.authorId discussion's authors' id
   * @param  {Array} attrs.replies replies
   * @param  {String} attrs.status discussion status
   * @param  {Array} attrs.approvers discussion approvers
   * @param  {Number[]} attrs.sending_managers_approvers_ids ids of matched sending managers approvers
   */
  constructor(attrs) {
    this.id = attrs.id//TODO will be deprecated
    this.discussionId = attrs.discussionId
    this.decorationUuid = attrs.decorationUuid
    this.range = attrs.range
    this.commentText = attrs.commentText
    this.suggestionText = attrs.suggestionText
    this.replacedHTML = attrs.replacedHTML
    this.replacedJSON = attrs.replacedJSON
    this.isSuggestion = attrs.isSuggestion
    this.isComment = attrs.isComment
    this.isActive = attrs.isActive
    this.contractId = attrs.contractId ?? null
    this.createdAt = attrs.createdAt ?? null
    this.authorAvatar = attrs.avatar ?? null
    this.authorRole = attrs.authorRole ?? null
    this.authorFullName = attrs.authorFullName ?? null
    this.authorId = attrs.authorId ?? null
    this.replies = attrs.replies ?? []
    this.status = attrs.status ?? discussionStatuses.DISCUSSION_OPEN
    this.approvers = attrs.approvers ?? []
    this.sending_managers_approvers_ids = attrs.sending_managers_approvers_ids ?? []
    this.internalChangesText = attrs.comment_general_contractor || ''
  }
}

export class DiscussionsFactory {

  constructor(editor) {
    this._editor = editor
  }

  create(data){
    const decorationUuid = this._editor.commands.generateUuid()
    //parse selected content to html
    let replacedHTML = null
    if (data.isSuggestion){
      const parser = initTaskReviewAndApproveParser()
      parser.commands.setContent(data.replacedJSON)
      replacedHTML = parser.getHTML()
      parser.destroy()
    } else {
      replacedHTML = null
    }
    return new Discussion({
      ...data,
      ...{
        decorationUuid,
        id: decorationUuid,
        replacedHTML: replacedHTML,
      }
    })
  }

  normalizeRawDiscussionData(discussion){
    return reactive({
      id: discussion.decoration_uuid,
      decorationUuid: discussion.decoration_uuid,
      discussionId: discussion.id,
      range: { from: discussion.decoration_from, to: discussion.decoration_to },
      commentText: discussion.comment,
      replacedHTML: discussion.replaced_content_html,
      replacedJSON: discussion.replaced_content_json,
      suggestionText: discussion.suggestion,
      isSuggestion: discussion.suggestion !== null,
      isComment: !!discussion.comment,
      isActive: false,
      contractId: discussion.subject_id,
      replies: discussion.replies ?? [],
      authorAvatar: discussion?.author_avatar,
      authorRole: discussion?.author_role,
      authorFullName: discussion?.author_fullname,
      createdAt: discussion?.created_at,
      authorId: discussion?.creator_id,
      status: discussion.status ?? discussionStatuses.DISCUSSION_OPEN,
      approvers: discussion.approvers ?? [],
      sending_managers_approvers_ids: discussion.sending_managers_approvers_ids ?? [],
      internalChangesText: discussion.comment_general_contractor || '',
    })
  }

}
