<template>
  <div>
    <div class="contract-approvers__wrap p-3 my-5">
      <div class="contract-users-approval-list">
        <div class="contract-users-approval-list__title">
          <span class="contract-users-approval-list__title-text">Approve</span>
          <span class="contract-users-approval-list__title-text">Reject</span>
        </div>

        <ul class="contract-users-approval-list__list">
          <div v-for="(approveUser, i) in externalFirst" :key="approveUser.user.user_id">
            <span v-if="i === 0" class="contract-users-approval-list__company">
              {{ contract.receiving_signer.company.name }}
            </span>
            <span v-if="i === 1" class="contract-users-approval-list__company">
              {{ contract.sending_signer.company.name }}
            </span>
          <li class="contract-users-approval-list__list-item">
            <div class="contract-users-approval-list__avatar-wrapper">
              <Avatar
                :size="32"
                :src="approveUser.user.photo_url"
                :display-name="[
                  approveUser.user.first_name,
                  approveUser.user.last_name,
                ]"
              />
            </div>

            <span class="contract-users-approval-list__name">
              {{ approveUser.user.full_name }}
            </span>

            <div
              class="contract-users-approval-list__label"
              :class="{
                'contract-users-approval-list__label--active':
                  approveUser.approved || approveUser.rejected,
              }"
            >
              <span class="contract-users-approval-list__label-text">{{
                approveUser.is_required ? 'Required' : 'Optional'
              }}</span>
            </div>

            <div class="contract-users-approval-list__actions">
              <template v-if="externalUser === approveUser.user_id || isExternal.approved">
                <button
                  type="button"
                  class="contract-users-approval-list__button"
                  :class="{
                    'contract-users-approval-list__button--approved':
                      approveUser.approved,
                  }"
                  :disabled="!isCurrentUser(approveUser) || !canEdit"
                  @click="approveContract(approveUser.id)"
                >
                  <CIcon
                    class="contract-users-approval-list__actions-item contract-users-approval-list__icon contract-users-approval-list__icon--approve"
                    name="cilCheckAlt"
                  />
                </button>

                <button
                  type="button"
                  class="contract-users-approval-list__button"
                  :class="{
                    'contract-users-approval-list__button--rejected':
                      approveUser.rejected,
                  }"
                  :disabled="!isCurrentUser(approveUser) || !canEdit"
                  @click="rejectContract(approveUser.id)"
                >
                  <CIcon
                    class="contract-users-approval-list__icon contract-users-approval-list__icon--reject"
                    name="cilX"
                  />
                </button>
              </template>

              <template
                v-else-if="
                  externalUser !== approveUser.user_id ||
                  disableAnotherApprovals
                "
              >
                <span class="contract-users-approval-list__na">N/A</span>
                <span class="contract-users-approval-list__na">N/A</span>
              </template>
            </div>
          </li>
          </div>
        </ul>
      </div>
      <div
        v-if="
          task.internal_assignee_id === user.id &&
          task.status !== statuses.CONTRACT_FULLY_EXECUTED
        "
      >
        <CButton
          :disabled="
            !externalFirst
              .filter((el) => el.is_required)
              .every((item) => item.approved) || !canEdit
          "
          color="primary"
          class="modal__btn mt-5 d-flex justify-content-center"
          @click="goToSignature"
        >
          Send for Signature
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Avatar from '@/components/Avatar.vue'
import taskStatuses from '@/utils/constants/taskStatuses'
import { permissionError } from '@/services/Notify/Toasts'

export default {
  name: 'ContractApprovers',
  components: { Avatar },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {},
  computed: {
    ...mapState({
      contract: (state) => state.contract.contract,
      canEdit: (state) => state.tasks.permissions.reviewAndApproveEdit,
    }),
    externalUser() {
      return this.task.external_assignee_id
    },
    isExternal() {
      return this.approveUsers.find((i) => i.user_id === this.externalUser)
    },
    approveUsers() {
      return this.contract.contract_approvals
    },
    disableApproveContractBtn() {
      if (this.approveUsers.every((o) => o.approved === true)) {
        return true
      }
      return false
    },
    statuses() {
      return taskStatuses
    },
    disableAnotherApprovals() {
      return !this.externalFirst[0].approved && !this.externalFirst[0].rejected
    },

    externalFirst() {
      if (this.user?.company?.id === this.task.assigned_company.id) {
        return (
          this.approveUsers?.filter(
            (user) => user.user_id === this.task.external_assignee.id,
          ) || []
        )
      } else {
        return this.approveUsers.reduce((acc, element) => {
          if (element.user_id === this.task.external_assignee_id) {
            return [element, ...acc]
          }
          return [...acc, element]
        }, [])
      }
    },

    user() {
      return this.$store.getters['user']
    },
  },

  methods: {
    isCurrentUser(user) {
      const userId = user.user_id || user.id
      return this.user.id === userId
    },
    goToSignature() {
      if (!this.$store.state.tasks.permissions.sendForSignatureView) {
        this.$notify(permissionError)
        return false
      }
      this.$router.push({
        name: 'TaskSignature',
        params: this.$route.params,
      })
      if (this.externalFirst.every((o) => o.approved === true)) {
        return true
      }
    },
    approveContract(id) {
      let item = this.externalFirst.find((i) => i.user_id === this.user.id)
      if (item && !item.approved) {
        if (item.id === id) {
          this.$http.contracts
            .approveContract(id)
            .then(async (res) => {
              await this.$store.dispatch('fetchTaskPermissions', this.$route.params.task_id)
              this.$store.commit('CHANGE_USER_APPROVE', res.data)
            })
            .catch((e) => {
              console.log(e)
            })
        }
      }
    },
    rejectContract(id) {
      let item = this.externalFirst.find((i) => i.user_id === this.user.id)
      if (item && !item.rejected) {
        if (item.id === id) {
          this.$http.contracts
            .rejectContract(id)
            .then(async (res) => {
              if (this.user.id === this.task.external_assignee_id) {
                this.$store.commit(
                  'RESET_APPROVE_AND_REJECT',
                  this.task.external_assignee_id,
                )
              }
              await this.$store.dispatch('fetchTaskPermissions', this.$route.params.task_id)
              this.$store.commit('CHANGE_USER_APPROVE', res.data)
            })
            .catch((e) => {
              console.log(e)
            })
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.contract-users-approval-list {
  &__list {
    list-style: none;
    padding: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 4px 16px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 12px;
  }

  &__title-text {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #677a89;
    text-transform: uppercase;

    &:first-child {
      margin-right: 10px;
    }
  }

  &__list-item {
    display: grid;
    grid-template-areas: 'avatar name actions' 'avatar label actions';
    grid-template-columns: auto 1fr auto;
    gap: 4px 8px;
    padding: 12px 25px 12px 0;
  }

  &__avatar-wrapper {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    align-self: center;
    grid-area: avatar;
    background-color: #c3cdd5;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__name {
    grid-area: name;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.003em;
    color: #1c262f;
  }

  &__company {
    font-size: 14px;
    color: #474747;
    //background: #e3e3e370;
    //padding: 2px 5px;
    display: block;
    font-weight: 600;
  }

  &__label {
    grid-area: label;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c3cdd5;
    border-radius: 24px;
    margin-right: auto;
    padding: 0 8px;

    &--active {
      background-color: #005694;
    }
  }

  &__label-text {
    color: #ffffff;
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
  }

  &__actions {
    grid-area: actions;
    display: flex;
    padding-top: 7px;
  }

  &__na {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #9aa8b5;

    &:first-child {
      margin-right: 24px;
    }
  }

  &__button {
    width: 17px;
    height: 17px;
    background-color: #9aa8b5;
    padding: 3px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 100%;
    overflow: hidden;

    &:first-child {
      margin-right: 24px;
    }

    &--rejected {
      background-color: #ff5257;
    }

    &--approved {
      background-color: #00a383;
    }
  }

  &__icon {
    &--approve {
    }

    &--reject {
    }
  }
}
</style>

<style scoped lang="scss">
.contract-approvers {
  &__wrap {
    background: #f2f5f8;
    border-radius: 8px;
    margin: 0 auto;
  }
}
</style>
