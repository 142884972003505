<template>
  <div class="text-secondary d-flex justify-content-center align-items-center"
       style="min-height: 70vh" >
    <div>
      <c-spinner size="sm"/> loading...
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageContentLoadingSpinner'
}
</script>
