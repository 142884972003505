<template>
  <template v-if="isSuggestion">
    <c-form-label class="mb-0"><b>Change to</b></c-form-label>
    <c-form-textarea
      v-model="suggestionText"
      :cols="7"
      @click.stop
      @keyup.ctrl.enter="handleSubmit"
    ></c-form-textarea>
  </template>

  <c-form-label class="mt-2 mb-0"><b>Comment</b></c-form-label>
  <c-form-textarea
    v-model="commentText"
    :cols="7"
    @click.stop
    @keyup.ctrl.enter="handleSubmit"
  ></c-form-textarea>
  <div
    style="flex-direction: column"
    class="buttons mt-3 d-flex justify-content-between align-items-center"
  >
    <div></div>
    <slot name="internalField"></slot>
    <div>
      <button
        class="btn btn-outline-secondary mr-2 btn-sm"
        @click.stop.prevent="handleCancel"
      >
        Cancel
      </button>
      <button class="btn btn-primary btn-sm" @click.stop.prevent="handleSubmit">
        Submit
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, defineEmits } from 'vue'
import { editorEvents } from '@/utils/editor/extensions/utils'
import { useStore } from 'vuex'
const store = useStore()
const emit = defineEmits(['updated', 'cancel'])
const commentText = ref('')
const suggestionText = ref(null)
const isSuggestion = ref(false)
const props = defineProps({
  discussion: {
    type: Object,
    default: () => ({}),
  },
})

const handleSubmit = () => {
  store.commit('discussions/UPDATE_DISCUSSION', {
    decorationUuid: props.discussion.decorationUuid,
    attrs: {
      commentText: commentText.value,
      suggestionText: suggestionText.value,
    },
  })

  window.editor
    .chain()
    .emitCustomEvent({
      name: editorEvents.EVENT_DISCUSSION_UPDATED,
      discussion: {
        ...props.discussion,
        ...{
          commentText: commentText.value,
          suggestionText: suggestionText.value,
        },
      },
    })
    .run()

  emit('updated', {
    commentText: commentText.value,
    suggestionText: suggestionText.value,
  })
}

const handleCancel = () => {
  emit('cancel')
}

onMounted(() => {
  commentText.value = props.discussion.commentText
  isSuggestion.value = props.discussion.isSuggestion
  if (isSuggestion.value) {
    suggestionText.value = props.discussion.suggestionText
  }
})
</script>

<style scoped lang="scss"></style>
