<template>
  <div class="reply-item__block">
    <div class="reply-item__wrap">
      <div class="reply-item d-flex justify-content-between align-items-start mt-3">
        <div class="d-flex justify-content-start align-items-center mb-3">
          <div class="avatar-wrapper">
            <img :src="reply.author_avatar" class="avatar" alt=""/>
            <img src="@/assets/images/chat.png" class="comment-icon" alt=""/>
          </div>
          <div class="bio d-flex flex-column">
            <span class="ml-2">{{ reply.author_fullname }}</span>
            <small class="text-secondary ml-2">{{ reply.author_role }}</small>
            <small class="text-secondary ml-2">{{ $DwT(reply.created_at) }}</small>
          </div>
        </div>
        <div v-if="canEdit" class="actions-items d-flex">
          <button class="btn btn-sm btn-ghost shadowless" v-if="isEditable" @click.stop.prevent="handleEdit">
            <CIcon name="cilPencil"/>
          </button>
          <span class="btn btn-sm shadowless" @click.stop.prevent="handleDelete" v-if="isDeletable">
        <CIcon name="cilTrash"/>
      </span>
        </div>
      </div>
      <discussion-reply-item-edit-form v-if="editMode" :reply="props.reply" @cancel="editMode = false" @updated="editMode = false"/>
      <div v-else style="white-space: break-spaces" @click="fullText = !fullText">
        {{ replyText }}
      </div>
    </div>
  </div>
  <confirmation-modal ref="confirmModal"/>
</template>

<script setup>
import { defineProps, defineEmits, computed, watch, ref } from 'vue'
import DiscussionReplyItemEditForm from '@/views/Projects/Project/Tasks/Task/View/ReviewApprove/partials/DiscussionReplyItemEditForm'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import { useStore } from 'vuex'
import ApiService from '@/services/ApiService'
import { truncate } from 'lodash'

const store = useStore()
const confirmModal = ref()
const fullText = ref(false)
const isEditable = computed(() => {
  return store.getters['user'].id === props.reply.creator_id && !hasRelies.value
})
const isDeletable = computed(() => {
  return store.getters['user'].id === props.reply.creator_id && !hasRelies.value
})
const replyText = computed(() => {
  return fullText.value ? props.reply.text : truncate(props.reply.text, { length: 100 })
})
const canEdit = computed(() => {
  return store.state.tasks.permissions.reviewAndApproveEdit
})
const editMode = ref(false)

const props = defineProps({
  reply: {
    type: Object,
    default: () => ({}),
  },
  replyFormOpen: {
    type: Boolean,
    default: false,
  }
})

const hasRelies = computed(() => {
  return store.getters['discussions/replyHasReplies']({
    discussionId: props.reply.discussion_id,
    replyId: props.reply.id,
  });
})

const emit = defineEmits(['edit'])
const handleEdit = () => {
  editMode.value = true
  emit('edit')
}
const handleDelete = async () => {
  if (await confirmModal.value.confirm({
    text: 'Do you really want to delete this comment?',
    title: ''
  })) {
    ApiService.api.discussions.deleteReply({replyId: props.reply.id})
      .then(res => {
        if (res.status === 200) {
          store.commit('discussions/DELETE_REPLY', {
            discussionId: props.reply.discussion_id,
            replyId: props.reply.id,
          })
        }
      })
  }
}

watch(() => props.replyFormOpen, (to) => {
  if (to) {
    editMode.value = false
  }
})
</script>

<style scoped lang="scss">
.reply-item {
  border-top: 1px solid lightgrey;
  padding: 1rem 0 0 0;

  img.avatar {
    height: 50px;
    width: 50px;
    border-radius: 50em;
    border: 1px solid lightgrey;
  }

  img.comment-icon {
    position: absolute;
    margin-left: -20px;
  }
}

.reply-item__wrap {
  float: right;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
}
.reply-item__block {
  display: flex;
  flex-direction: column;
}
</style>
