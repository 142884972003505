<template>
  <div class="discussions-section">
    <h5 class="my-3 clickable ml-2" @click="toggleVisibility">
      {{ sectionTitle }}
      <section-visibility-toggle :is-visible="visible" />
    </h5>
    <CCollapse :visible="visible">
      <div
        v-show="visible"
        class="threads-wrapper p-2 overflow-scroll"
        style="max-height: 700px"
      >
        <template v-if="discussions.length">
          <div
            v-for="discussion in discussions"
            :key="discussion.decorationUuid"
            class="card mb-3 clickable"
            @click.capture="$emit('discussion:click', discussion)"
          >
            <discussion-item
              :task="task"
              :discussion="discussion"
              :editor="editor"
              @deleted="discussion = $emit('discussion:deleted', discussion)"
            />
          </div>
        </template>
        <div v-else class="text-secondary mb-3">no items</div>
      </div>
    </CCollapse>
  </div>
</template>

<script>
import SectionVisibilityToggle from '@/components/Forms/CompanyUsersEditForm/SectionVisibilityToggle.vue'
import DiscussionItem from '@/views/Projects/Project/Tasks/Task/View/ReviewApprove/partials/DiscussionItem.vue'
import { DISCUSSION_STATUSES } from '@/store/discussions.module'
import {orderBy} from "lodash";

export default {
  name: 'ReviewApproveSidebarDiscussionsSection',
  components: { SectionVisibilityToggle, DiscussionItem },
  props: {
    editor: {
      type: Object,
      default: () => ({}),
    },
    task: {
      type: Object,
      default: () => ({}),
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: 'All',
      validator(value) {
        return [
          'All',
          DISCUSSION_STATUSES.DISCUSSION_STATUS_REJECTED,
          DISCUSSION_STATUSES.DISCUSSION_STATUS_APPROVED,
          DISCUSSION_STATUSES.DISCUSSION_STATUS_OPEN,
        ].includes(value)
      },
    },
  },
  emits: ['discussion:click', 'discussion:deleted', 'toggled'],
  data() {
    return {
      visible: this.isVisible,
    }
  },
  computed: {
    sectionTitle() {
      return `${this.status} Comments & Edits ( ${this.discussions.length} )`
    },
    discussions() {
      let arr = this.status === 'All'
          ? this.$store.getters['discussions/allDiscussions']
          : this.$store.getters['discussions/allDiscussions'].filter(
              (d) => d.status === this.status,
            )

      return orderBy(arr, ['range.from'], ['asc'])
    },
  },
  watch: {
    isVisible(to) {
      this.visible = to
    },
  },
  methods: {
    toggleVisibility() {
      this.visible = !this.visible
      this.$emit('toggled', this.visible)
    },
  },
}
</script>

<style scoped lang="scss">
.discussions-section {
  border-bottom: 1px solid lightgrey;
}
</style>
