<template>
  <div class="card-body" :class="cardClasses">
    <div class="d-flex justify-content-between align-items-start">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="avatar-wrapper">
          <Avatar
            class="avatar"
            :size="50"
            :src="discussion.authorAvatar"
            :display-name="[...discussion.authorFullName.split(' ')]"
          />
<!--          <img src="@/assets/images/chat.png" class="comment-icon" alt="" />-->
        </div>
        <div class="bio d-flex flex-column">
          <span class="ml-2">{{
            discussion.authorFullName ?? authUser.fullName
          }}</span>
          <small v-if="discussion.authorRole" class="text-secondary ml-2">{{
            discussion.authorRole ?? authUser.role?.show_name
          }}</small>
          <small v-if="discussion.createdAt" class="text-secondary ml-2">{{
            toLocalTime(discussion.createdAt)
          }}</small>
          <small v-else class="text-secondary ml-2" style="filter: blur(3px)"
            >****-**-** **:**:**</small
          >
        </div>
      </div>
      <div class="actions-items d-xl-flex align-items-start">
        <discussion-item-approvers
          ref="discussionItemApprovers"
          :internal-text="internalChangesText"
          :approve-changes="approveChanges"
          :discussion="discussion"
          :open-edit-modal-show="showOpenEditModal"
          :task="task"
          @updated="updatedDiscussion"
          @confirmRejectModal="confirmRejectDiscussion"
          @approveState="getApproveState"
          @close-open-edit="closeEditModal"
          @cancel-open-edit="openEditModal"
          @discussion-rolled-back="handleDiscussionRolledBack"
          @discussion-approved="handleDiscussionApproved"
        />

        <button
          v-if="isEditable && !this.approvedOrRejected && canEdit"
          class="btn btn-sm btn-ghost shadowless"
          :disabled="editMode"
          @click.stop="handleEdit"
        >
          <CIcon name="cilPencil" />
        </button>
        <span
          v-if="isDeletable && !this.approvedOrRejected && canEdit"
          class="btn btn-sm shadowless"
          @click.stop="handleDelete"
        >
          <CIcon name="cilTrash" />
        </span>
        <button
          v-if="
            !this.approvedOrRejected && authUser.id === task.internal_assignee_id &&
            discussion.authorId !== task.internal_assignee_id && canEdit
          "
          class="btn btn-sm btn-ghost shadowless"
          @click.stop="handleOpenInternalField"
        >
          <CIcon name="cilPencil" />
        </button>
      </div>
    </div>
    <!-- edit form    -->
    <div v-if="editMode">
      <discussion-item-edit-form
        :discussion="discussion"
        @cancel="editMode = false"
        @updated="editMode = false"
      >
      </discussion-item-edit-form>
    </div>
    <template v-else>
      <div>
        <div
          v-if="discussion.replacedHTML"
          class="clickable"
          @click.stop="truncateReplacedText = !truncateReplacedText"
        >
          <div><small>From:</small></div>
          <div v-html="replacedContent"></div>
        </div>
        <div
          class="clickable"
          @click.stop="truncateSuggestedText = !truncateSuggestedText"
        >
          <div>
            <small>{{ discussion.replacedHTML ? 'To' : 'Change' }}:</small>
          </div>
          <p style="white-space: break-spaces">{{ suggestionText }}</p>
        </div>
        <div
          v-if="
            authUser.id === task.internal_assignee_id &&
            discussion.authorId !== authUser.id &&
            openInternalEditField
          "
        >
          <CFormFloating class="mb-3" style="width: 100%">
            <CFormTextarea
              id="floatingInput"
              v-model="internalChangesText"
              v-autosize
              style="resize: none"
              class="w-100 discussion-item__textarea"
            />
            <CFormLabel
              style="width: max-content"
              for="floatingInput"
              class="internal-suggestion-input-label"
              >Suggested edit by {{ internalCompanyName }}:
            </CFormLabel>
          </CFormFloating>
          <div>
            <CButton
              color="primary"
              variant="outline"
              class="modal__btn clear"
              @click="cancelChanges"
            >
              {{ $t('Cancel') }}
            </CButton>
            <CButton
              class="ml-2 modal__btn btn-primary"
              color="primary"
              @click="confirmChanges"
            >
              {{ $t('Post') }}
            </CButton>
          </div>
        </div>
        <div
          v-else-if="!openInternalEditField && addInternalText"
          class="clickable"
          @click.stop="truncateInternalChangesText = !truncateInternalChangesText"
        >
          <div><small>Suggested edit by {{ internalCompanyName }}:</small></div>
          <p>{{ internalText }}</p>
        </div>
      </div>
      <small>Comment:</small>
      <div style="white-space: break-spaces" @click="truncateCommentText = !truncateCommentText">{{ replacedComment }}</div>
      <div
        v-if="
          discussion.status === 'Open' &&
          authUser.id === task.internal_assignee_id && canEdit
        "
        style="
          padding-right: 18px;
          float: right;
          padding-top: 1rem;
          justify-content: right;
          position: relative;
          padding-top: 1.5rem;
        "
        class="d-flex align-items-center"
      >
        <span class="text-success mr-3" @click="$refs.discussionItemApprovers.confirmApprove">
          <CIcon name="cilApprove" />
          Push
        </span>
        <span class="text-danger" @click="rejectDiscussion">
          <CIcon name="cilReject" />
          Reject
        </span>
      </div>
    </template>
    <!-- end edit form    -->

    <CButton
      v-if="discussion.status === 'Rejected' && isInternal && canEdit"
      color="primary"
      style="
        margin-left: auto;
        margin-top: 12px;
        float: right;
        margin-bottom: 0 !important;
      "
      variant="outline"
      class="modal__btn clear mb-3"
      @click="openEditModal"
    >
      Open Edit
    </CButton>
    <div>
      <discussion-item-replies
        :contract-approve="contract.canCreateComment"
        :discussion="discussion"
        :discussion-edit-mode="editMode"
        @form-showed="handleRelyFormShowed"
      />
    </div>
    <confirmation-modal ref="confirmationModal" />
  </div>
</template>

<script>
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import DiscussionItemEditForm from '@/views/Projects/Project/Tasks/Task/View/ReviewApprove/partials/DiscussionItemEditForm.vue'
import DiscussionItemReplies from '@/views/Projects/Project/Tasks/Task/View/ReviewApprove/partials/DiscussionItemReplies.vue'
import DiscussionItemApprovers from '@/views/Projects/Project/Tasks/Task/View/ReviewApprove/partials/DiscussionItemApprovers.vue'
import {
  DISCUSSION_APPROVEMENT_STATUSES,
  DISCUSSION_STATUSES,
} from '@/store/discussions.module'
import { editorEvents } from '@/utils/editor/extensions/utils'
import dayjs from 'dayjs'
import { truncate } from 'lodash'
import ApiService from '@/services/ApiService'
import { mapState } from 'vuex'
import Avatar from "@/components/Avatar.vue";

export default {
  name: 'DiscussionItem',
  components: {
    Avatar,
    DiscussionItemApprovers,
    DiscussionItemReplies,
    DiscussionItemEditForm,
    ConfirmationModal,
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    discussion: {
      type: Object,
      default: () => ({}),
    },
    editor: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['deleted'],
  data() {
    return {
      approveChanges: 0,
      authorRoleToContract: '',
      editMode: false,
      truncateReplacedText: true,
      truncateSuggestedText: true,
      truncateCommentText: true,
      truncateInternalChangesText: true,
      internalChangesTextOrigin: '',
      internalChangesText: '',
      openInternalEditField: false,
      addInternalText: false,
      showOpenEditModal: false,
    }
  },
  computed: {
    ...mapState({
      contract: (state) => state.contract.contract,
      internalCompanyName: (state) => state.tasks?.task?.internal_assignee?.company_name,
      canEdit: (state) => state.tasks.permissions.reviewAndApproveEdit,
    }),
    mutateDiscussion() {
      return this.discussion
    },
    isInternal() {
      return this.task.internal_assignee_id === this.authUser.id
    },
    replacedContent() {
      return this.truncateReplacedText
        ? truncate(this.discussion.replacedHTML, { length: 100 })
        : this.discussion.replacedHTML
    },
    replacedComment() {
      return this.truncateCommentText
        ? truncate(this.discussion.commentText, { length: 100 })
        : this.discussion.commentText
    },
    suggestionText() {
      return this.truncateSuggestedText
        ? truncate(this.discussion.suggestionText, { length: 100 })
        : this.discussion.suggestionText
    },
    internalText() {
      return this.truncateInternalChangesText
        ? truncate(this.internalChangesText, { length: 100 })
        : this.internalChangesText
    },
    isEditable() {
      if (this.authUser.id === this.discussion.authorId && this.authUser.id === this.task.internal_assignee.id) {
        return true
      }

      return (
        this.authUser.id === this.discussion.authorId &&
        (!this.discussion.replies || this.discussion.replies.length === 0)
      )
    },
    isDeletable() {
      return (
        this.authUser.id === this.discussion.authorId &&
        (!this.discussion.replies || this.discussion.replies.length === 0)
      )
    },
    approvedOrRejected() {
      return [
        DISCUSSION_STATUSES.DISCUSSION_STATUS_APPROVED,
        DISCUSSION_STATUSES.DISCUSSION_STATUS_REJECTED,
      ].includes(this.discussion.status)
    },
    approversNotInPendingStatus() {
      return this.discussion.approvers.filter((a) =>
        [
          DISCUSSION_APPROVEMENT_STATUSES.DISCUSSION_APPROVEMENT_STATUS_APPROVED,
          DISCUSSION_APPROVEMENT_STATUSES.DISCUSSION_APPROVEMENT_STATUS_REJECTED,
        ].includes(a.status),
      )
    },
    cardClasses() {
      return {
        shadow: this.discussion.isActive,
        'status-approved':
          this.discussion.status ===
          DISCUSSION_STATUSES.DISCUSSION_STATUS_APPROVED,
        'status-rejected':
          this.discussion.status ===
          DISCUSSION_STATUSES.DISCUSSION_STATUS_REJECTED,
      }
    },
  },
  created() {
    this.authorRoleToContract =
      this.contract.sending_signer_id === parseInt(this.authUser.id)
        ? 'Contractor'
        : 'Subcontractor'

    this.internalChangesText = this.discussion.internalChangesText
    this.addInternalText = !!this.internalChangesText

  },
  methods: {
    updatedDiscussion() {
      this.editor.chain().focus()
      this.$store.commit('discussions/SET_STATE', {
        key: 'discussions',
        value: this.$store.getters['discussions/allDiscussions'].map((d) => { d.isActive =  false; return d})
      })
      this.showOpenEditModal = false
    },
    rejectDiscussion() {
      this.$refs.discussionItemApprovers.showRejectConfirmationModal = true
    },
    confirmRejectDiscussion() {
      this.mutateDiscussion.status = 'Rejected'
    },
    openEditModal() {
      this.showOpenEditModal = !this.showOpenEditModal
    },
    closeEditModal() {
      this.showOpenEditModal = !this.showOpenEditModal
      this.mutateDiscussion.status = 'Open'
    },
    getApproveState(value) {
      this.approveChanges = value
    },
    confirmChanges() {
      this.openInternalEditField = false
      this.addInternalText = true
      ApiService.api.discussions
        .changeDiscussion({
          discussionId: this.discussion.discussionId,
          params: {
            comment_general_contractor: this.internalChangesText,
          },
        })
        .then((res) => {
          this.$store.commit('discussions/ADD_INTERNAL_TEXT', {
            discussionId: this.discussion.discussionId,
            internalChangesText: this.internalChangesText,
          })
        })
    },
    handleOpenInternalField() {
      this.openInternalEditField = true
      this.internalChangesTextOrigin = this.internalChangesText
    },
    cancelChanges() {
      this.openInternalEditField = false
      this.internalChangesText = this.internalChangesTextOrigin
      this.internalChangesTextOrigin = ''
    },
    toLocalTime(value) {
      return value ? dayjs(value).format('MM/DD/YYYY h:mm A') : value
    },
    async handleDelete() {
      if (
        await this.$refs.confirmationModal.confirm({
          text: this.$t('Do you really want to delete this comment?'),
          title: '',
        })
      ) {
        this.$store.commit('discussions/DELETE_DISCUSSION', {
          decorationUuid: this.discussion.decorationUuid,
        })

        this.$emit('deleted', this.discussion)
      }
    },
    handleEdit() {
      this.editMode = true
      this.openInternalEditField = true
    },
    handleRelyFormShowed() {
      this.editMode = false
    },
    handleDiscussionApproved(discussion) {
      //emit event catched in discussionObserver.js
      this.editor.commands
        .getEmitter()
        .emit(editorEvents.EVENT_DISCUSSION_APPROVED, { discussion })
    },
    handleDiscussionRolledBack(discussion) {
      //emit event catched in discussionObserver.js
      this.editor.commands
        .getEmitter()
        .emit(editorEvents.EVENT_DISCUSSION_ROLLED_BACK, { discussion })
    },
    handleRejectSuggestion() {
      alert('reject suggestion')
    },
  },
}
</script>

<style scoped lang="scss">
.card-body {
  &.status-rejected {
    background-color: rgba(229, 83, 83, 0.05);
  }

  &.status-approved {
    background-color: rgba(46, 184, 92, 0.05);
  }

  padding: 10px;

  .avatar {
    height: 50px;
    width: 50px;
    border-radius: 50em;
    border: 1px solid lightgrey;
  }

  img.comment-icon {
    position: absolute;
    margin-left: -20px;
  }
}

.discussion-item__textarea {
  border: 1px solid #b1b7c1;
  border-radius: 8px;
  min-height: 80px;
  margin-top: 4px;
}

.internal-suggestion-input-label {
  height: 14%;
  background: #ffffff;
  opacity: 1 !important;
  top: 11.5px !important;
  padding-top: 1px !important;
  border-radius: 8px;
}

.card-body {
  display: flex;
  flex-direction: column;
}
</style>
