<template>
    <div>
        <div class="d-flex justify-content-end mb-3">
            <span v-if="discussionsObserver.busy"
                  class="btn-spinner c-loading-button-spinner spinner-border spinner-border-sm"></span>
        </div>
        <review-approve-sidebar-discussions-section
            :is-visible="sectionIsVisible('All')"
            :editor="editor"
            status="All"
            :task="task"
            @discussion:click="handleClick"
            @discussion:deleted="handleDiscussionDeleted"
            @toggled="visibleSection === 'All'"
        />
        <review-approve-sidebar-discussions-section
            :is-visible="sectionIsVisible('Open')"
            :editor="editor"
            status="Open"
            :task="task"
            @discussion:click="handleClick"
            @discussion:deleted="handleDiscussionDeleted"
            @toggled="visibleSection === 'Open'"
        />
        <review-approve-sidebar-discussions-section
            :is-visible="sectionIsVisible('Approved')"
            :editor="editor"
            status="Approved"
            :task="task"
            @discussion:click="handleClick"
            @discussion:deleted="handleDiscussionDeleted"
            @toggled="visibleSection === 'Approved'"
        />
        <review-approve-sidebar-discussions-section
            :is-visible="sectionIsVisible('Rejected')"
            :editor="editor"
            status="Rejected"
            :task="task"
            @discussion:click="handleClick"
            @discussion:deleted="handleDiscussionDeleted"
            @toggled="visibleSection === 'Rejected'"
        />

        <ContractApprovers :task="task"/>
    </div>
</template>

<script>
import {editorEvents} from '@/utils/editor/extensions/utils'
import DiscussionsObserver from '@/utils/editor/tools/discussionsObserver'
import {DiscussionsFactory} from '@/utils/editor/tools/discussionsFactory'
import ReviewApproveSidebarDiscussionsSection
    from '@/views/Projects/Project/Tasks/Task/View/ReviewApprove/partials/ReviewApproveSidebarDiscussionsSection'
import ContractApprovers from '@/views/Projects/Project/Tasks/Task/View/ReviewApprove/partials/ContractApprovers'

export default {
    name: 'ReviewApproveSidebar',
    components: {ReviewApproveSidebarDiscussionsSection, ContractApprovers},
    props: {
        editor: {
            type: Object,
            default: () => null,
        },
        contract: {
            type: Object,
            default: () => ({}),
        },
        task: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        editorWrapper: () => document.querySelector('.template-editor-viewer'),
        discussionsObserver: null,
        discussionFactory: null,
        decorations: [],
        discussionsFilter: {
            status: null,
        },
        visibleSection: '',
    }),
    computed: {
        discussions() {
            return this.$store.getters['discussions/allDiscussions']
        },
    },
    created() {
        const _discussions = this.contract.discussions
        this.decorations = this.contract.decorations
        this.discussionsObserver = new DiscussionsObserver(this.editor, this.$store)
        this.discussionFactory = new DiscussionsFactory(this.editor)
        if (_discussions && _discussions.length) {
            const discussions = _discussions?.map((diss) => {
                return this.discussionFactory.normalizeRawDiscussionData(diss)
            })
            this.$store.commit('discussions/SET_STATE', {
                key: 'discussions',
                value: discussions,
            })
        } else {
            this.$store.commit('discussions/SET_STATE', {
                key: 'discussions',
                value: [],
            })
        }
    },
    mounted() {
        this.$LaravelEcho
            .private('contract.' + this.contract.id)
            .listen(
                '.contract.discussion.created',
                ({discussion, contract_decorations}) => {
                    const discussionToPush =
                        this.discussionFactory.normalizeRawDiscussionData(
                            discussion,
                            contract_decorations,
                        )
                    this.$store.commit('discussions/PUSH_DISCUSSION', discussionToPush)
                    this.editor.commands.refreshDecorationsView()
                },
            )
            .listen('.contract.discussion.deleted', (payload) => {
                this.$store.commit('discussions/DELETE_DISCUSSION', payload)
                this.editor.commands.refreshDecorationsView()
            })
            .listen('.contract.discussion.updated', ({discussion}) => {
                const updatedDiscussion =
                    this.discussionFactory.normalizeRawDiscussionData(
                        discussion,
                        this.decorations,
                    )
                this.$store.commit('discussions/UPDATE_DISCUSSION', {
                    decorationUuid: updatedDiscussion.decorationUuid,
                    attrs: updatedDiscussion,
                })
                this.editor.commands.refreshDecorationsView()
            })
            .listen('.contract.discussion.reply.created', ({discussion_reply}) => {
                this.$store.commit('discussions/PUSH_DISCUSSION_REPLY', {
                    discussionId: discussion_reply.discussion_id,
                    reply: discussion_reply,
                })
            })
            .listen('.contract.discussion.reply.updated', ({discussion_reply}) => {
                this.$store.commit('discussions/UPDATE_REPLY', {
                    discussionId: discussion_reply.discussion_id,
                    replyId: discussion_reply.id,
                    value: discussion_reply,
                })
            })
            .listen(
                '.contract.discussion.reply.deleted',
                ({reply_id, discussion_id}) => {
                    this.$store.commit('discussions/DELETE_REPLY', {
                        discussionId: discussion_id,
                        replyId: reply_id,
                    })
                },
            )
    },
    methods: {
        sectionIsVisible(name) {
            return this.visibleSection === name
        },
        handleDiscussionDeleted(discussion) {
            this.editor
                .chain()
                .focus()
                .setTextSelection(0)
                .emitCustomEvent({
                    name: editorEvents.EVENT_DISCUSSION_DELETED,
                    discussion,
                })
                .run()
        },
        handleClick(discussion) {
            this.toggleDiscussionSelection(discussion)
        },
        toggleDiscussionSelection(discussion) {
            if (!discussion) return
            this.$store.commit('discussions/SET_STATE', {
                discussions: this.$store.getters['discussions/allDiscussions'].map(
                    (d) => {
                        d.isActive = d.decorationUuid === discussion.decorationUuid

                        return d
                    },
                ),
            })

            this.editor
                .chain()
                .focus()
                .setTextSelection(discussion.range.from)
                .command(() => {
                    let windowTopPosition = window.scrollY
                    let discussionElement = document.querySelector(`.id-${discussion.id}`)

                    if (discussionElement?.parentElement?.parentElement?.parentElement?.tagName === 'TR') {
                        discussionElement = discussionElement.parentElement.parentElement
                    }
                    if (discussionElement) {
                        let count = discussionElement.offsetTop - this.editorWrapper().scrollTop - 200
                        this.editorWrapper().scrollBy({top: count, left: 0})
                        window.scrollTo({top: windowTopPosition})
                    }

                    return true
                })
                .run()
        },
    },
}
</script>

<style scoped lang="scss">
.threads-wrapper {
    max-height: 600px;
    overflow-y: auto;
}

.btn-spinner {
    position: absolute;
    right: 12px;
    top: 19px;
    color: lightgrey;
}
</style>
