<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 64 64" role="img">
    <svg width="40" height="40" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 1.5H4.54569V2.04569H4V1.5Z" :fill="fillColor"/>
      <path d="M0.25 7.75H7.75V0.25H0.25V7.75ZM0.75 0.75H7.25V7.25H0.75V0.75Z" :fill="fillColor"/>
      <path d="M4.4583 5.61031C4.43338 5.65286 4.39779 5.68817 4.35504 5.71274C4.31228 5.73731 4.26386 5.7503 4.21455 5.7504C4.17068 5.7504 4.12742 5.74018 4.0882 5.72054C4.04898 5.7009 4.01488 5.67239 3.9886 5.63727C3.96232 5.60215 3.94459 5.56138 3.93681 5.51821C3.92904 5.47504 3.93144 5.43066 3.94381 5.38858L4.50116 3.49362C4.55722 3.30338 4.53818 3.09888 4.44797 2.92226C4.35775 2.74564 4.20322 2.61035 4.01623 2.54427C3.82923 2.47818 3.62401 2.48634 3.44285 2.56706C3.26169 2.64777 3.11839 2.7949 3.04247 2.97812L2.85938 3.41928L3.3212 3.61093L3.5043 3.16976C3.53115 3.10471 3.58196 3.05244 3.64623 3.02376C3.7105 2.99508 3.78333 2.99216 3.84969 3.01562C3.91604 3.03907 3.97086 3.08711 4.00283 3.14981C4.0348 3.21251 4.04148 3.28509 4.02148 3.35258L3.46414 5.24753C3.42984 5.36416 3.42319 5.48719 3.44474 5.60684C3.46628 5.72649 3.51542 5.83947 3.58825 5.93682C3.66108 6.03416 3.7556 6.11319 3.86431 6.16763C3.97301 6.22207 4.09291 6.25042 4.21448 6.25043C4.35119 6.25017 4.48546 6.2142 4.60399 6.14609C4.72252 6.07798 4.8212 5.98009 4.89027 5.86211L5.1262 5.45722L4.69423 5.20542L4.4583 5.61031Z" :fill="fillColor"/>
    </svg>
  </svg>
</template>

<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'default'
  }
})

const typeColorType = new Map([
  ['default', 'black'],
  ['approved', 'green'],
  ['rejected', 'red'],
])

const fillColor = computed(() => typeColorType.get(props.type) ?? 'black' )
</script>
