import { editorEvents } from '@/utils/editor/extensions/utils'
import ApiService from '@/services/ApiService'
import { ref } from 'vue'
import Vue from 'vue'
import store from '@/store'

export default class DiscussionsObserver {
  constructor(editor, store) {
    this.editor = editor
    this.store = store
    this.init()
    this.busy = ref(false)
  }

  get emitter() {
    return this.editor ? this.editor.commands.getEmitter() : null
  }

  isBusy() {
    this.busy.value = true
  }

  isFree() {
    this.busy.value = false
  }

  init() {
    //on discussion created
    this.emitter.on(
      editorEvents.EVENT_DISCUSSION_CREATED,
      async ({ discussion }) => {
        this.isBusy()
        const res = await ApiService.api.discussions.storeDiscussion({
          decoration_uuid: discussion.decorationUuid,
          decoration_from: discussion.range.from,
          decoration_to: discussion.range.to,
          subject_id: discussion.contractId,
          comment: discussion.commentText,
          suggestion: discussion.suggestionText,
          replaced_content_json: discussion.replacedJSON,
          replaced_content_html: discussion.replacedHTML,
        })
        if (res.status === 201) {
          const discussionData = res.data.data
          this.store.commit('discussions/UPDATE_DISCUSSION', {
            decorationUuid: discussion.decorationUuid,
            attrs: {
              discussionId: discussionData.id,
              createdAt: discussionData.created_at,
              authorRole: discussionData.author_role,
              authorAvatar: discussionData.author_avatar,
              approvers: discussionData.approvers ?? [],
              sending_managers_approvers_ids:
                discussionData.sending_managers_approvers_ids ?? [],
            },
          })
        }
        this.isFree()
      },
    )

    //on discussion deleted
    this.emitter.on(
      editorEvents.EVENT_DISCUSSION_DELETED,
      async ({ discussion }) => {
        this.isBusy()
        await ApiService.api.discussions.deleteDiscussion({
          discussionId: discussion.discussionId,
        })

        this.isFree()
      },
    )

    //discussion updated on client, here we sync data with back
    this.emitter.on(
      editorEvents.EVENT_DISCUSSION_UPDATED,
      async ({ discussion }) => {
        this.isBusy()
        try {
          await ApiService.api.discussions.updateDiscussion({
            discussionId: discussion.discussionId,
            comment: discussion.commentText,
            suggestion: discussion.suggestionText,
          })
        } catch (e) {
          this.isFree()
          throw e
        }
        this.isFree()
      },
    )

    //discussion approve
    this.emitter.on(
      editorEvents.EVENT_DISCUSSION_APPROVED,
      async ({ discussion }) => {
        //proceed only with suggestions
        // if (!discussion.isSuggestion) return
        this.isBusy()
        if (discussion.internalChangesText) {
          await this.editor
            .chain()
            .insertContentAt(discussion.range, discussion.internalChangesText)
            .run()
        } else if (discussion.suggestionText) {
          await this.editor
            .chain()
            .insertContentAt(discussion.range, discussion.suggestionText)
            .run()
        } else {
          await this.editor.chain().deleteRange(discussion.range).run()
        }
        const html = await this.editor.getHTML()

        const decorationsPreparedData = this.store.getters[
          'discussions/allDiscussions'
        ].map((d) => {
          return { id: d.decorationUuid, from: d.range.from, to: d.range.to }
        })
        await ApiService.api.contracts.syncDecorations({
          decorations: decorationsPreparedData,
          contract_id: discussion.contractId,
        })

        await ApiService.api.contracts
          .applySuggestion({
            contract_id: discussion.contractId,
            discussion_id: discussion.discussionId,
            decoration_from: discussion.range.from,
            decoration_to: discussion.range.to,
            content: html,
          })
          .then((res) => {
            store.commit('GET_CONTRACT', res.data.data)
          })

        this.isFree()
      },
    )

    //discussion rolled back
    this.emitter.on(
      editorEvents.EVENT_DISCUSSION_ROLLED_BACK,
      async ({ discussion }) => {
        //
      },
    )
  }
}
