<template>
  <span class="mr-2">
    <CPopover :visible="showApprovers" :trigger="!task.is_external ? 'hover' : ''" placement="top" >
      <template #content>
        <div v-if="!task.is_external">
          <div v-for="approver in discussionApprovers" :key="approver.id" class="mb-2" style="line-height: 15px" >
            <div
              class="d-flex align-center"
              :class="{
                'text-success': approver.status === 'Approved',
                'text-danger': approver.status === 'Rejected',
              }"
            >
              <div style="padding-right: 3px">
                <CIcon v-if="approver.status === 'Approved'" name="cilApprove" />
                <CIcon v-if="approver.status === 'Rejected'" name="cilReject"/>
                <CIcon v-else-if="approver.status === 'Pending' && authUser.id !== approver.user_id" name="cilClock" />
              </div>
              <p style="margin-bottom: 0.1rem">
                {{ approver.full_name }}
              </p>
            </div>

            <div v-if="approver.status !== 'Pending'" class="text-secondary">
              <small style="margin-left: 19px">{{ dayjs(approver.updated_at).format('MM/DD/YYYY h:mm A') }}</small>
            </div>
          </div>
        </div>
      </template>
      <template #toggler="{ on }" v-if="!task.is_external">
        <CIcon v-if="decisionCanBeRolledBack && canEdit" name="cilX" class="mr-2" @click.stop="handleResetApprovement" />

        <span v-if="discussionApprovers.length" :class="approversTitleClasses" v-on="on" >
          {{ togglerLabel }}
          <IconInfoSquare :type="infoIconType" />
        </span>
        <div v-if="isApproverTrue && discussion.status === 'Open' && canEdit" style="cursor: pointer" class="d-flex" >
          <div @click.prevent.stop="isApproverItem.status === 'Rejected' ? () => false : handleReject(true)">
            <svg
              style="width: 23px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.00033 0.667969C4.39199 0.667969 0.666992 4.39297 0.666992 9.0013C0.666992 13.6096 4.39199 17.3346 9.00033 17.3346C13.6087 17.3346 17.3337 13.6096 17.3337 9.0013C17.3337 4.39297 13.6087 0.667969 9.00033 0.667969ZM13.167 11.993L11.992 13.168L9.00033 10.1763L6.00866 13.168L4.83366 11.993L7.82533 9.0013L4.83366 6.00964L6.00866 4.83464L9.00033 7.8263L11.992 4.83464L13.167 6.00964L10.1753 9.0013L13.167 11.993Z"
                :fill="
                  isApproverItem.status === 'Rejected' ? '#FF5257' : '#677A89'
                "
              />
            </svg>
          </div>
          <div style="position: relative; top: -1px; cursor: pointer; right: 2px" @click.prevent.stop="isApproverItem.status === 'Approved' ? () => false : handleApprove(true)" >
            <svg
              style="width: 23px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.0003 1.66797C5.40033 1.66797 1.66699 5.4013 1.66699 10.0013C1.66699 14.6013 5.40033 18.3346 10.0003 18.3346C14.6003 18.3346 18.3337 14.6013 18.3337 10.0013C18.3337 5.4013 14.6003 1.66797 10.0003 1.66797ZM8.33366 14.168L4.16699 10.0013L5.34199 8.8263L8.33366 11.8096L14.6587 5.48464L15.8337 6.66797L8.33366 14.168Z"
                :fill="
                  isApproverItem.status === 'Approved' ? '#00a383' : '#677A89'
                "
              />
            </svg>
          </div>
        </div>
      </template>
    </CPopover>
  </span>

  <div v-if="openEditModalShow" class="discussion-approve-confirmation-prompt">
    <div>
      <h5>Are you sure you want to open the suggestion?</h5>
    </div>
    <div class="mt-4">
      <button class="btn btn-secondary mr-3" color="link" @click.prevent.stop="$emit('cancelOpenEdit')" >
        Cancel
      </button>
      <submit-btn class="btn" color="primary" @click.prevent.stop="handleOpen" >
        Confirm
      </submit-btn>
    </div>
  </div>
  <div v-if="showRejectConfirmationModal" class="discussion-approve-confirmation-prompt" >
    <div>
      <h5>Are you sure you want to reject the suggestion?</h5>
    </div>
    <div class="mt-4">
      <button class="btn btn-secondary mr-3" color="link" @click.prevent.stop="showRejectConfirmationModal = false" >
        Cancel
      </button>
      <submit-btn class="btn" color="primary" @click.prevent.stop="handleReject(false)" >
        Confirm
      </submit-btn>
    </div>
  </div>
  <div v-if="showApproveConfirmationModal" class="discussion-approve-confirmation-prompt" >
    <div v-if="checkIfAnyTrueOrFalse">
      <h5>
        All the users who have already approved or rejected the contract will be
        required to approve again.
      </h5>
      <h5>This action cannot be undone.</h5>
    </div>
    <div v-else>
      <h5>This action cannot be undone.</h5>
      <h5>Are you sure you want to approve the suggestion?</h5>
    </div>
    <div class="mt-4">
      <button class="btn btn-secondary mr-3" color="link" @click.prevent.stop="cancelApprove" >
        Cancel
      </button>
      <submit-btn class="btn" color="primary" @click.prevent.stop="handleApprove(false)" >
        Confirm
      </submit-btn>
    </div>
  </div>
</template>

<script setup>
import ApiService from '@/services/ApiService'
import IconInfoSquare from '@/assets/icons/IconInfoSquare'
import {defineProps, computed, ref, defineEmits, defineExpose} from 'vue'
import {useStore} from 'vuex'
import {
  DISCUSSION_APPROVEMENT_STATUSES,
  DISCUSSION_STATUSES,
} from '@/store/discussions.module'
import dayjs from 'dayjs'

const store = useStore()
const props = defineProps({
  openEditModalShow: {
    type: Boolean,
    default: false,
  },
  task: {
    type: Object,
    default: () => ({}),
  },
  discussion: {
    type: Object,
    default: () => ({}),
  },
  internalText: {
    type: String,
    default: '',
  },
  approveChanges: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits([
  'discussionApproved',
  'discussionRolledBack',
  'approveState',
  'confirmRejectModal',
  'updated',
])

const showApprovers = ref(false)
const contract = computed(() => {
  return store.state.contract.contract.contract_approvals
})

const canEdit = computed(() => {
  return store.state.tasks.permissions.reviewAndApproveEdit
})

const checkIfAnyTrueOrFalse = computed(() => {
  return (
    contract.value.some((i) => i.approved === true) ||
    contract.value.some((i) => i.rejected === true)
  )
})

const discussionApprovers = computed(() => {
  return (
    store.getters['discussions/getDiscussionById'](
      props.discussion.discussionId,
    )?.approvers ?? []
  )
})
const inPendingStatus = computed(() => {
  return (
    currentApprover.value.status ===
    DISCUSSION_APPROVEMENT_STATUSES.DISCUSSION_APPROVEMENT_STATUS_PENDING
  )
})

const allApprovers = computed(() => {
  return props.discussion.approvers
})
const isApproverTrue = computed(() => {
  return !!(allApprovers.value.find((i) => i.approver_id === isAuthUser.value))
})
const isApproverItem = computed(() => {
  return allApprovers.value.find((i) => i.approver_id === isAuthUser.value)
})

const isAuthUser = computed(() => {
  return store.state.app.user.id
})

const mutateDiscussion = computed(() => {
  return this.discussion
})

const currentApprover = computed(() => {
  let approver = props.discussion.approvers.find(
    (a) => a.id === store.getters['user'].id,
  )
  //check sending managers list
  if (!approver) {
    if (
      props.discussion.sending_managers_approvers_ids.includes(
        store.getters['user'].id,
      )
    ) {
      approver = props.discussion.approvers.find((a) => a.id === null)
    }
  }
  return approver
})

const togglerLabel = computed(() => {
  if (
    props.discussion.status === DISCUSSION_STATUSES.DISCUSSION_STATUS_APPROVED
  ) {
    return 'Approved'
  } else if (
    props.discussion.status === DISCUSSION_STATUSES.DISCUSSION_STATUS_REJECTED
  ) {
    return 'Rejected'
  } else if (store.getters['user'].id !== props.task.external_assignee_id) {
    return 'Approvers'
  }
})

const infoIconType = computed(() => {
  if (
    props.discussion.status === DISCUSSION_STATUSES.DISCUSSION_STATUS_REJECTED
  ) {
    return 'rejected'
  }
  if (
    props.discussion.status === DISCUSSION_STATUSES.DISCUSSION_STATUS_APPROVED
  ) {
    return 'approved'
  }
  return 'default'
})

const approversTitleClasses = computed(() => {
  return {
    'text-success':
      props.discussion.status ===
      DISCUSSION_STATUSES.DISCUSSION_STATUS_APPROVED,
    'text-danger':
      props.discussion.status ===
      DISCUSSION_STATUSES.DISCUSSION_STATUS_REJECTED,
    'd-inline-flex': true,
  }
})

/*APPROVEMENT*/
const showApproveConfirmationModal = ref(false)
const showRejectConfirmationModal = ref(false)
const approveConfirmed = ref(false)
const cancelApprove = () => {
  approveConfirmed.value = false
  showApproveConfirmationModal.value = false
}
const confirmApprove = () => {
  showApproveConfirmationModal.value = true
  approveConfirmed.value = true
}
const handleApprove = (fromList) => {
  ApiService.api.discussions
    .approveDiscussion({
      discussionId: props.discussion.discussionId,
      params: {
        confirm_approve: true,
        from_list: fromList,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        // store.commit('discussions/SET_INTERNAL_CHANGES', {
        //   discussionId: props.discussion.discussionId,
        //   internalChanges: props.internalText,
        // })
        if (res.data.data.suggestion === null) {
          res.data.data.suggestion = ''
        }
        const {status, approvers} = res.data.data
        //if discussion has been approved - emit it for discussionObserver
        if (
          status !== props.discussion.status &&
          status === DISCUSSION_STATUSES.DISCUSSION_STATUS_APPROVED
        ) {
          emit('discussionApproved', props.discussion)
        }
        updateDiscussion({status, approvers})
        showApproveConfirmationModal.value = false
        emit('updated')
      }
    })
    .catch((err) => {
      if (err?.response?.status === 428) {
        console.log(this.authUser.id, this.task.internal_assignee_id)
      }
    })
}

const handleReject = (fromList) => {
  ApiService.api.discussions
    .rejectDiscussion({
      discussionId: props.discussion.discussionId,
      params: { from_list: fromList },
    })
    .then((res) => {
      if (res.status === 200) {
        const {status, approvers} = res.data.data
        updateDiscussion({status, approvers})
      }
      showRejectConfirmationModal.value = false
      emit('updated')
    })
    .catch((err) => (console.log(err)))
}

const handleOpen = () => {
  ApiService.api.discussions
    .openDiscussion({
      discussionId: props.discussion.discussionId,
    })
    .then((res) => {
      if (res.status === 200) {
        const {status, approvers} = res.data.data
        updateDiscussion({status, approvers})
      }
      showRejectConfirmationModal.value = false
      emit('updated')
    })
    .catch((err) => (console.log(err)))
}

const decisionCanBeRolledBack = computed(() => {
  return (
    currentApprover.value &&
    currentApprover.value.status !== 'Pending' &&
    props.discussion.status !== DISCUSSION_STATUSES.DISCUSSION_STATUS_APPROVED
  )
})
const handleResetApprovement = () => {
  ApiService.api.discussions
    .resetDiscussionApprovement({
      discussionId: props.discussion.discussionId,
    })
    .then((res) => {
      if (res.status === 200) {
        const {status, approvers} = res.data.data
        updateDiscussion({status, approvers})
      }
    })
}

const updateDiscussion = ({status, approvers}) => {
  store.commit('discussions/UPDATE_DISCUSSION', {
    decorationUuid: props.discussion.decorationUuid,
    attrs: {status, approvers},
  })
}

defineExpose({
  confirmApprove,
  cancelApprove,
  showRejectConfirmationModal,
})
</script>

<style scoped>
.discussion-approve-confirmation-prompt {
  position: absolute;
  top: 0;
  background-color: #303c54f7;
  border-radius: 5px;
  height: 100%;
  left: 0;
  right: 0;
  color: white;
  padding: 2rem;
  text-align: center;
  z-index: 1;
}
</style>
